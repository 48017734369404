



// const AppConfig = APP_ENVIRONMENT == 'LIVE' ? ConfigProduction : ConfigStaging
// export const base_url = "http://127.0.0.1:8000/";
// export const base_url = "https://hunger.thestorywallcafe.com/";
export const base_url = "https://www.thehungerspoint.ca/";
export const openstreetmap = "https://nominatim.openstreetmap.org";

const convertToBase64 = (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result;
        resolve(base64Image);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(imageFile);
    });
  };
  
export { convertToBase64 };



  const formatDateTime = (datetimeStr) => {
    // Create a new Date object from the datetime string
    const datetime = new Date(datetimeStr);
  
    // Define an array of month names
    const monthNames = [
      "January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December"
    ];
  
    // Format the date in "dd MonthName yyyy" format
    const formattedDate = `${datetime.getDate()} ${monthNames[datetime.getMonth()]} ${datetime.getFullYear()}`;
  
    // Format the time in "hh:mm:ss" format
    const formattedTime = datetime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
  
    // Combine the formatted date and time
    const formattedDatetime = `${formattedDate} ${formattedTime}`;
  
    return formattedDatetime;
  };
  export { formatDateTime };